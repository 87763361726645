/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Card, message,
  Form,
  Input,
  Checkbox,
} from "antd";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";


const SignUp = (props) => {

  const [Listbussines, setLitbusiness] = useState([]);
  const [Bussines, setBusiness] = useState([]);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [terminos, setTerminos] = useState('');
  const history = useHistory();
  const [token, setToken] = useState(localStorage.getItem('Token'));
  const [expire, setExpire] = useState('');

  useEffect(() => {    
    getBussines();
  }, []);

  
  const axiosJWT = axios.create();

  axios.interceptors.request.use( 
    config => {
      config.headers.Authorization= `Bearer ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
    );

  const getBussines = async () => {
    const response = await axiosJWT.get('https://api.trevol.com.co/business', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setLitbusiness(response.data);
  };


  const onFinish = async () => {
    try {
      var business = Bussines.value;
      await axios.post("https://api.trevol.com.co/users", {
        idemp: business,
        usucodigo: id,
        name,
        email,
        password,
        confPassword: passwordConfirm
      });
      message.success("Usuario creado correctamente!", 3000)
      history.go(0);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {
    setBusiness(value);
  };

  const { cantidad, moneda, catchError, onError, onCancel } = props;
  const paypalKey = "AcUwPBu9WLc-ZNL4KILGzuBV9j9uy_gzORy3-esc8LvrbeDKFiTC96tFPxAgct-RCVf1sLIS0cHmZsSd"

  const OnApprove = (data, detalle) => {
    // llamar a la API de backend para almacenar los detalles de la transacción 
    console.log("Payapl aprobado")
    console.log(data.subscriptionID)
  };
  const createSubscription = (datos, actions) => {
    return actions.subscription.create({
      'plan_id': "P-60B49649YS2913909MOPYSTA",
    });
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              title={<h5>Registrar Usuario</h5>}
              bordered="false"
            >
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="row-col"
              >
                <Form.Item
                  name="idemp"
                  rules={[
                    { required: true, message: "Por seleccione una Empresa!" },
                  ]}
                >
                  <Select
                    labelInValue
                    defaultValue={{ value: "0", key: "0", label: "Seleccione" }}
                    onChange={handleChange}
                    options={Listbussines}
                  />
                </Form.Item>
                <Form.Item
                  name="id"
                  value={id} onChange={(e) => setId(e.target.value)}
                  rules={[
                    { required: true, message: "Por favor ingrese número de documento!" },
                  ]}
                >
                  <Input placeholder="Identificación" />
                </Form.Item>
                <Form.Item
                  name="name"
                  value={name} onChange={(e) => setName(e.target.value)}
                  rules={[
                    { required: true, message: "Por favor ingrese Nombre!" },
                  ]}
                >
                  <Input placeholder="Nombre" />
                </Form.Item>
                <Form.Item
                  name="email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                  rules={[
                    { required: true, message: "Por favor ingrese Correo!" },
                  ]}
                >
                  <Input placeholder="Correo" />
                </Form.Item>
                <Form.Item
                  name="password"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  rules={[
                    { required: true, message: "Por favor ingrese una Contraseña!" },
                  ]}
                >
                  <Input placeholder="Contraseña" />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)}
                  rules={[
                    { required: true, message: "Por favor confirme la Contraseña!" },
                  ]}
                >
                  <Input placeholder="Contraseña" />
                </Form.Item>
                <Form.Item
                  value={terminos} onChange={(e) => setTerminos(e.target.value)}
                  name="terminos" valuePropName="checked">
                  <Checkbox>
                    Acepto{" "}
                    <a href="#pablo" className="font-bold text-dark">
                      Terminos y Condiciones.
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SIGN UP
                  </Button>
                </Form.Item>
              </Form>
              <PayPalButton
                cantidad={cantidad}
                moneda={moneda}
                createSubscription={(datos, detalles) => createSubscription(datos, detalles)}
                onApprove={(datos, detalles) => OnApprove(datos, detalles)}
                onError={(err) => onError(err)}
                catchError={(err) => catchError(err)}
                onCancel={(err) => onCancel(err)}
                options={{
                  clientId: paypalKey,
                  vault: true
                }}
                style={{
                  forma: 'rect',
                  color: 'blue',
                  layout: 'horizontal',
                  label: 'subscribe',
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SignUp;
