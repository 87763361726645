import { useEffect, useLayoutEffect, useState, useRef, useContext } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { Pagination, PaginationProps } from "antd";

import { AppContext } from "../AppContext";

import ReportPath from './Report/ReportPath'
import ReportToolbar from './Report/ReportToolbar';
import NewReportToolbar from './Report/NewReportToolbar';

import PowerBiService, { MyWorkspace } from '../Services/PowerBiService'
import PowerBi from '../Services/PowerBi'
import { PowerBiDataset, PowerBiReport, PowerBiWorkspace } from '../models/PowerBiModels';

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";

import { Box } from '@mui/material';
// ensure Power BI JavaScript API has loaded
require('powerbi-models');
require('powerbi-client');



export type ViewMode = "FitToPage" | "FitToWidth" | "ActualSize";

const Report = () => {
    const navigate = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const embedContainer = useRef(document.createElement("div"));
    const [pages, setPages] = useState<powerbi.Page[] | undefined>(undefined);
    let pag: powerbi.Page[] | undefined
    type QuizParams = {
        path: string;
    };
    let Token = localStorage.getItem("TokenADD") == null ? "" : localStorage.getItem("TokenADD");

    const { path } = useParams<QuizParams>();
    const { appContextData, setCurrentWorkspaceId } = useContext(AppContext)

    const [embeddedReport, setEmbeddedReport] = useState<powerbi.Report | null>(null);
    const [embeddedNewReport, setEmbeddedNewReport] = useState<powerbi.Embed | null>(null);

    const [embedType, setEmbedType] = useState<"ExistingReport" | "NewReport" | null>(null);
    const [reportType, setReportType] = useState<"PowerBiReport" | "PaginatedReport" | null>(null);

    const [editMode, setEditMode] = useState(false);
    const [showNavigation, setShowNavigation] = useState(true);
    const [showFiltersPane, setShowFiltersPane] = useState(true);
    const [showBookmarksPane, setShowBookmarksPane] = useState(false);
    const [viewMode, setViewMode] = useState<ViewMode>("FitToPage");

    const [reportPath, setReportPath] = useState("");

    const isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    const embedExistingReport = async (WorkspaceId: string, ReportId: string) => {
        const params = new URLSearchParams(window.location.search);
        const openInEditMode = isMobile.any() ? false : (params.get('edit') === "true");
        setEditMode(openInEditMode);

        const defaultShowNavigation: boolean = true;
        setShowNavigation(defaultShowNavigation);
        const defaultShowFilterPane: boolean = false;
        setShowFiltersPane(defaultShowFilterPane);
        const defaultShowBookmarksPane: boolean = false;
        setShowBookmarksPane(defaultShowBookmarksPane);


        let report: PowerBiReport = await PowerBi.GetReport(WorkspaceId, ReportId, Token);

        if (appContextData.currentWorkspaceName && report.name) {
            setReportPath(appContextData.currentWorkspaceName + " > " + report.name);
        }
        else {
            setReportPath("...");
        }

        if (report.reportType === "PaginatedReport") {
            setReportType("PaginatedReport")
            embedPaginatedReport(WorkspaceId, report);
            return;
        }

        setEmbedType("ExistingReport")
        setReportType("PowerBiReport")

        let accessToken = await PowerBiService.GetAccessToken();

        var config: powerbi.IEmbedConfiguration = {
            type: 'report',
            id: report.id,
            embedUrl: report.embedUrl,
            accessToken: accessToken == null ? '' : accessToken == null ? '' : accessToken,
            tokenType: models.TokenType.Aad,
            viewMode: openInEditMode ? models.ViewMode.Edit : models.ViewMode.View,
            permissions: models.Permissions.All,
            settings: {
                panes: {
                    pageNavigation: { position: models.PageNavigationPosition.Left },
                    filters: { visible: defaultShowFilterPane, expanded: false },
                    bookmarks: { visible: defaultShowBookmarksPane }
                },
                bars: {
                    actionBar: { visible: false }
                },
                persistentFiltersEnabled: true,
                personalBookmarksEnabled: true,
                layoutType: isMobile.any() ? models.LayoutType.MobilePortrait : 0,
            }
        };

        console.log("config", config);

        // Embed the report and display it within the div container                
        var embeddedReport: powerbi.Report = (window.powerbi.embed(embedContainer.current, config) as powerbi.Report);
        embeddedReport.on("filtersApplied", (args) => { embeddedReport.savePersistentFilters(); });
        embeddedReport.on("dataSelected", (args) => { embeddedReport.savePersistentFilters(); });
        embeddedReport.on("visualClicked", (args) => { embeddedReport.savePersistentFilters(); });
        embeddedReport.on("selectionChanged", (args) => { embeddedReport.savePersistentFilters(); });

        setEmbeddedReport(embeddedReport);
        console.log(embeddedReport);

        embeddedReport.on("saved", (event: any) => {

            console.log("embeddedReport.on", event);

            if (event.detail.saveAs) {
                var newReportId = event.detail.reportObjectId;
                var newReportName = event.detail.reportName;
                navigate.push("/reports/" + WorkspaceId + "/" + newReportId + "/?edit=true&newReport=true");
            }

        });

    };

    const embedNewReport = async (WorkspaceId: string, DatasetId: string) => {

        let dataset: PowerBiDataset = await PowerBi.GetDataset(WorkspaceId, DatasetId, Token);
        let accessToken = await PowerBiService.GetAccessToken();

        var config: powerbi.IEmbedConfiguration = {
            type: 'report',
            datasetId: dataset.id,
            embedUrl: dataset.createReportEmbedURL,
            accessToken: accessToken == null ? '' : accessToken,
            tokenType: models.TokenType.Aad,
            settings: {
                panes: {
                    pageNavigation: { position: models.PageNavigationPosition.Left },
                    filters: { visible: true, expanded: false },
                    bookmarks: { visible: true }
                },
                bars: {
                    actionBar: { visible: false }
                },
                persistentFiltersEnabled: true,
                personalBookmarksEnabled: true,
                layoutType: isMobile.any() ? models.LayoutType.MobilePortrait : undefined,
            }
        };

        // Embed the report and display it within the div container.
        var embeddedNewReport: powerbi.Embed = window.powerbi.createReport(embedContainer.current, config);
        setEmbeddedNewReport(embeddedNewReport);

        embeddedNewReport.on("saved", (event: any) => {

            console.log("Saved");
            console.log(event.detail);

            // get ID and name of new report            
            var newReportId = event.detail.reportObjectId;
            var newReportName = event.detail.reportName;
            navigate.push("/reports/" + WorkspaceId + "/" + newReportId + "/?edit=true&newReport=true")
        });

    };

    const embedPaginatedReport = async (WorkspaceId: string, Report: PowerBiReport) => {

        let accessToken = await PowerBiService.GetAccessToken();

        var config: powerbi.IEmbedConfiguration = {
            type: 'report',
            id: Report.id,
            embedUrl: Report.embedUrl,
            accessToken: accessToken == null ? '' : accessToken,
            tokenType: models.TokenType.Aad,
            settings: {}
        };

        console.log("config", config);

        // Embed the report and display it within the div container                
        var embeddedReport: powerbi.Report = (window.powerbi.embed(embedContainer.current, config) as powerbi.Report);

    };

    // set height of embed container relative to height of window
    const setReportContainerHeight = () => {
        var reportContainer: HTMLElement = embedContainer.current;
        var reportContainerTopPosition = reportType === "PaginatedReport" ? 88 : 122;
        reportContainer.style.height = (window.innerHeight - reportContainerTopPosition - 10) + "px";
    };

    useLayoutEffect(() => {
        if (isAuthenticated) {
            setReportContainerHeight();
            window.addEventListener("resize", setReportContainerHeight);
        }
    }, [reportType]);

    // call Power BI REST API and embed report
    useEffect(() => {



        window.powerbi.reset(embedContainer.current);

        // handle URL for embedding existing report
        if (path.includes("@")) {
            setEmbedType("ExistingReport");
            let parts = path.split("@");
            let workspaceId = parts[0];
            let reportId = parts[1];
            if (workspaceId !== appContextData.currentWorkspaceId) {
                setCurrentWorkspaceId(workspaceId);
            }
            embedExistingReport(workspaceId, reportId);
            return;
        }

        // handle URL for embedding new report
        if (path.includes("$")) {
            setEmbedType("NewReport");
            let parts = path.split("$");
            let workspaceId = parts[0];
            let datasetId = parts[1];
            if (workspaceId !== appContextData.currentWorkspaceId) {
                setCurrentWorkspaceId(workspaceId);
            }
            embedNewReport(workspaceId, datasetId);
            return;
        }
    }, [path, appContextData]);

    async function Obenerpagina() {
        return await embeddedReport?.getPages();
    }

    const onChange: PaginationProps['onChange'] = async (page, pageSize) => {
        let pag = await Obenerpagina()
        if (pag != undefined) {
            embeddedReport?.setPage(pag[page]?.name)
            setPages(pag);
        }
    };



    

    return (
        <div className='reporte'>

            <ReportPath reportPath={reportPath} />

            {embedType === "ExistingReport" && reportType === "PowerBiReport" &&
                <> <ReportToolbar report={embeddedReport}
                    editMode={editMode} setEditMode={setEditMode} showNavigation={showNavigation} setShowNavigation={setShowNavigation}
                    showFiltersPane={showFiltersPane} setShowFiltersPane={setShowFiltersPane} viewMode={viewMode} setViewMode={setViewMode}
                    showBookmarksPane={showBookmarksPane} setShowBookmarksPane={setShowBookmarksPane} />
                    <Pagination onChange={onChange} defaultCurrent={1} total={pages != undefined ? pages.length * 10 : 20} /></>
            }

            {embedType === "NewReport" &&
                <NewReportToolbar report={embeddedNewReport}
                    showFiltersPane={showFiltersPane} setShowFiltersPane={setShowFiltersPane} viewMode={viewMode} setViewMode={setViewMode}
                    showBookmarksPane={showBookmarksPane} setShowBookmarksPane={setShowBookmarksPane} />}


            <Box ref={embedContainer} sx={{ width: "100%" }} />

        </div>
    );

};

export default Report;