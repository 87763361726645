/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useContext, useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, Redirect, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import axios from "axios";
import { AppContext } from "../../AppContext";
import { PowerBiWorkspace, PowerBiReport, PowerBiDataset } from '../../models/PowerBiModels';




function Sidenav(color: string) {
  const navigate = useHistory();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { appContextData, setCurrentWorkspaceId } = useContext(AppContext);
  const [token, setToken] = useState(localStorage.getItem('Token'));


  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" />
      </svg>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z" />
    </svg>,
  ];



  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];



  // Similar to componentDidMount and componentDidUpdate:


  const GetReports = () => {
    const myelement: any[] = [];
    let vl_sContador = 1;
    appContextData.reports?.map((report: PowerBiReport) => (
      myelement.push(<>      
      
      <Menu.Item key={report.id}>
        <NavLink  onClick={Validar} to={{
          pathname: "/reports/" + appContextData.currentWorkspaceId  +"@"+ report.id
        }}>
          <span
            className="icon"
            style={{
              background: page === color ? color : "",
            }}
          >
            {tables}
          </span>
          <span className="label">{report.name}</span>
        </NavLink>
      </Menu.Item ></>)

    ))

    return myelement;

  }

  const Validar =async  () => {
    try {
      let ip ="";
      const URL_API = "https://api.ipify.org/?format=json";
      await  fetch(URL_API)
      .then(respuestaRaw => respuestaRaw.json())
      .then(respuesta => {
         ip = respuesta.ip;        
      });


      const Valido = await axios.post('https://api.trevol.com.co/verify', {
        business: localStorage.getItem('IDEMP'),
        email: localStorage.getItem('Email'),
        ip:ip
      },{headers: {
        Authorization: `Bearer ${token}`
      }});      
      return Valido;
    } catch (error) {   
        await Logout ();
        navigate.push('/')     
    }

  }

  const GetDatasets = () => {
    const myelement: any[] = [];
    appContextData.datasets?.map((dataset: PowerBiDataset) => (
      myelement.push(<><Menu.Item key={dataset.id}>
        <NavLink onClick={Validar} to={{
          pathname: "/reports/" + appContextData.currentWorkspaceId + "$" + dataset.id
        }}>
          <span
            className="icon"
            style={{
              background: page === color ? color : "",
            }}
          >
            {tables}
          </span>
          <span  className="label">{dataset.name}</span>
        </NavLink>
      </Menu.Item ></>
      )
    ))
    return myelement;

  }
  const Logout = async() => {
    try {
      const Token = await axios.post('https://api.trevol.com.co/logout', {
        business: localStorage.getItem('IDEMP'),
        email: localStorage.getItem('Email')
      });
                 
    } catch (error) {    
      console.error(error);      
    }
  }




  return (
    <>

      <div className="brand">
        <img src={logo} alt="" />
        <span></span>
      </div>
      <hr />      
      <Menu theme="light" mode="inline" id="menu">
        <Menu.Item className="menu-item-header" key="22">
          Reportes
        </Menu.Item>
        {GetReports()}
        <Menu.Item className="menu-item-header" key="23">
          Datasets
        </Menu.Item>
        {GetDatasets()}
        { localStorage.getItem("Profile")?.toString() ==="1"?
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Registrar Usuarios</span>
          </NavLink>
        </Menu.Item>:''
        }
      </Menu>           
    </>
  );
}

export default Sidenav;
