import { PowerBiReport ,PowerBiDataset} from "./../models/PowerBiModels";

const PowerBiApiRoot: string = "https://api.powerbi.com/v1.0/myorg/";
export default class PowerBi {


  static GetReport = async (WorkspaceId: string, ReportId: string, pi_sToken: string | null): Promise<PowerBiReport> => {
    var restUrl: string;
    restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/reports/" + ReportId;
    return fetch(restUrl, {
      headers: {
        "Accept": "application/json;odata.metadata=minimal;",
        "Authorization": "Bearer " + pi_sToken
      }
    }).then(response => response.json())
      .then(response => { return response; });
  }
  static GetDataset = async (WorkspaceId: string, DatasetId: string,pi_sToken:string|null): Promise<PowerBiDataset> => {
    var restUrl: string;
    restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/datasets/" + DatasetId;
    return fetch(restUrl, {
      headers: {
        "Accept": "application/json;odata.metadata=minimal;",
        "Authorization": "Bearer "+ pi_sToken
      }
    }).then(response => response.json())
      .then(response => { return response; });
  }


}