import { useState, useEffect, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from "react"
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/Report.css";
import Report from "./pages/Report";
import axios from "axios";
import { AppContext } from "./AppContext";
import PowerBiService, { MyWorkspace } from './Services/PowerBiService';
import { PowerBiWorkspace, PowerBiReport, PowerBiDataset } from './models/PowerBiModels';




function App() {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [Reportes, setReportes] = useState(0);
  const IDEMP ="DEMO" ;
  const [workspaces, setWorkspaces] = useState<PowerBiWorkspace[]>();
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>("");
  const [currentWorkspaceName, setCurrentWorkspaceName] = useState(IDEMP);
  const [currentWorkspaceIsReadOnly, setCurrentWorkspaceIsReadOnly] = useState(false);
  const [currentWorkspaceIsPremium, setCurrentWorkspaceIsPremium] = useState(false);
  const [reports, setReports] = useState<PowerBiReport[] | undefined>(undefined);
  const [datasets, setDatasets] = useState<PowerBiDataset[] | undefined>(undefined);
  const [Token, setToken] = useState("");
  const [workspaceArtifactsLoading, setWorkspaceArtifactsLoading] = useState(false);

  
  useEffect(() => {
    const GetToken = async () => {
      if (IDEMP !== null) {
        if (Token == "") {
          await axios.get('https://api.trevol.com.co/GetToken').then(respueta => {
            setToken(respueta.data.access_token);
            localStorage.setItem("TokenADD", respueta.data.access_token)
          });

        }
        if(Token !== ""){
        let powerBiWorkspaces = workspaces;
        powerBiWorkspaces = await PowerBiService.GetWorkspaces(Token);
        setWorkspaces(powerBiWorkspaces);

        let workspace =  powerBiWorkspaces!.find(workspace => workspace.name === currentWorkspaceName);

        setCurrentWorkspaceId(workspace!.id);
        setCurrentWorkspaceName(workspace!.name);
        setCurrentWorkspaceIsReadOnly(workspace!.isReadOnly ? true : false);
        setCurrentWorkspaceIsPremium(workspace!.isOnDedicatedCapacity ? true : false);
        setWorkspaceArtifactsLoading(true);
        setReports(await PowerBiService.GetReports(workspace!.id, Token));
        setDatasets(await PowerBiService.GetDatasets(workspace!.id, Token));
        await new Promise(f => setTimeout(f, 1000));
        setWorkspaceArtifactsLoading(false);

      }
      }
    }
    GetToken();

  }, [Token,IDEMP]);

  const refreshReportList = () => {
    const refreshReportListAsync = async () => {
      setReports(await PowerBiService.GetReports(currentWorkspaceId,Token));
    };
    refreshReportListAsync();
  };

  return (
    <AppContext.Provider value={{
      appContextData: {
        currentWorkspaceId: currentWorkspaceId,
        currentWorkspaceName: currentWorkspaceName == null ? "" :currentWorkspaceName,
        currentWorkspaceIsReadOnly: currentWorkspaceIsReadOnly,
        currentWorkspaceIsPremium: currentWorkspaceIsPremium,
        workspaces: workspaces,
        reports: reports,
        datasets: datasets,
        workspaceArtifactsLoading: workspaceArtifactsLoading,
      }, setCurrentWorkspaceId, refreshReportList
    }}>
      <div className="App">
        <Switch>
          <Route path="/" exact component={SignIn} />
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/tables" component={Tables} />
            <Route exact path="/reports/:path" component={Report} />
            
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/rtl" component={Rtl} />
            <Route exact path="/profile" component={Profile} />
          </Main>
        </Switch>
      </div>
    </AppContext.Provider>
  );
}

export default App;
