
import { PowerBiPermissionScopes } from "../authConfig";
import axios from "axios"
import qs from "qs";
const PowerBiApiRoot = "https://api.powerbi.com/v1.0/myorg/";

var PowerBiWorkspace;

export const MyWorkspace = PowerBiWorkspace = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "MyWorkspace",
  isReadOnly: false,
  isOnDedicatedCapacity: true
}

export default class PowerBiService {

  static GetAccessToken = async () => {
    return localStorage.getItem("TokenADD");
  }

  static GetWorkspaces = async (pi_sToken) => {
    var resp = "";
    var config = {
      method: 'get',
      url: PowerBiApiRoot + "groups/",
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.value

  }

  static GetWorkspace = async (WorkspaceId, pi_sToken) => {

    if (WorkspaceId === MyWorkspace.id) {
      return new Promise < PowerBiWorkspace > (resolve => resolve(MyWorkspace))
    }
    var resp = "";
    var config = {
      method: 'get',
      url: PowerBiApiRoot + "groups/" + "?$filter=id eq '" + WorkspaceId + "'",
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.value

  }

  static GetReports = async (WorkspaceId, pi_sToken) => {
    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "/Reports/";
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/Reports/";
    }

    var resp = "";
    var config = {
      method: 'get',
      url: restUrl,
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.value
  }

  static GetReport = async (WorkspaceId, ReportId, pi_sToken) => {
    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "Reports/" + ReportId;
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/reports/" + ReportId;
    }
    var resp = "";
    var config = {
      method: 'get',
      url: restUrl,
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp
  }

  static GetReportToken = async (WorkspaceId, ReportId, pi_sToken) => {
    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "Reports/" + ReportId;
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/reports/" + ReportId + "/GenerateToken";
    }
    var resp = "";
    var config = {
      method: 'post',
      url: restUrl,
      headers: {
        "Authorization": "Bearer " + pi_sToken
      },
      data: {
        "accessLevel": "Edit",
        "allowSaveAs": "true"
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.token
  }

  static GetDatasets = async (WorkspaceId, pi_sToken) => {
    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "datasets/";
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/datasets/";
    }

    var resp = "";
    var config = {
      method: 'get',
      url: restUrl,
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.value
  }

  static GetDataset = async (WorkspaceId, DatasetId, pi_sToken) => {
    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "datasets/" + DatasetId;
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/datasets/" + DatasetId;
    }
    var resp = "";
    var config = {
      method: 'get',
      url: restUrl,
      headers: {
        "Authorization": "Bearer " + pi_sToken
      }
    };

    const respuesta = await axios(config)
      .then(function (response) {
        resp = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    //console.log(JSON.parse(data))

    return resp.value
  }

  static ExportReport = async (WorkspaceId, ReportId, ExportRequest, pi_sToken) => {

    console.log("ExportReport");

    const setExportStatusMessage = ExportRequest.exportStatusCallback;

    console.log("setExportStatusMessage", setExportStatusMessage);

    let exportJob = await PowerBiService.StartExportReportJob(WorkspaceId, ReportId, ExportRequest);

    if (setExportStatusMessage) { setExportStatusMessage("[" + exportJob.percentComplete + "%] " + exportJob.status); }

    let waitTime = "";

    while (exportJob.status !== "Succeeded" && exportJob.status !== "Failed") {
      await new Promise(f => setTimeout(f, 1000));
      if (setExportStatusMessage) { setExportStatusMessage("[" + exportJob.percentComplete + "%] " + exportJob.status + waitTime); }
      exportJob = await PowerBiService.GetExportReportJobStatus(WorkspaceId, ReportId, exportJob.id);
      console.log(exportJob.status);
      waitTime += ".";
    }

    if (setExportStatusMessage) { setExportStatusMessage("[" + exportJob.percentComplete + "%] " + exportJob.status + waitTime); }

    await fetch(exportJob.resourceLocation, {
      method: 'GET',
      headers: { "Authorization": "Bearer " + pi_sToken }
    }).then((res) => { return res.blob(); })
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = exportJob.reportName + exportJob.resourceFileExtension;
        a.click();
      });

    return;

  }

  static StartExportReportJob = async (WorkspaceId, ReportId, exportRequest, pi_sToken) => {

    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "reports/" + ReportId + "/ExportTo";
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/reports/" + ReportId + "/ExportTo";
    }

    let postBody = JSON.stringify(exportRequest);

    return fetch(restUrl, {
      method: 'POST',
      body: postBody,
      headers: {
        "Accept": "application/json;odata.metadata=minimal;",
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + pi_sToken
      }
    }).then(response => response.json())
      .then((response) => {
        return response;
      });
  }

  static GetExportReportJobStatus = async (WorkspaceId, ReportId, ExportId, pi_sToken) => {

    var restUrl;
    if (WorkspaceId === MyWorkspace.id) {
      restUrl = PowerBiApiRoot + "reports/" + ReportId + "/exports/" + ExportId;
    }
    else {
      restUrl = PowerBiApiRoot + "groups/" + WorkspaceId + "/reports/" + ReportId + "/exports/" + ExportId;
    }

    return fetch(restUrl, {      
      headers: {
        "Access-Control-Allow-Origin" : "*",
        "Accept": "application/json;odata.metadata=minimal;",
        "Authorization": "Bearer " + pi_sToken
      }
    }).then(response => response.json())
      .then((response) => {
        return response;
      });
  }


}