/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Layout, Menu, Button, message, Row, Col, Typography, Form, Input, Switch } from "antd";
import signinbg from "../assets/images/img-signin.jpeg";
import logo from "../assets/images/logo.png";



function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const SignIn = () => {
  const [email, setEmail] = useState('');
  const [business, setBusiness] = useState('');
  const [password, setPassword] = useState('');  

  const navigate = useHistory();


  const onFinish = async (e) => {
    try {
      let ip ="";
      const URL_API = "https://api.ipify.org/?format=json";
      await  fetch(URL_API)
      .then(respuestaRaw => respuestaRaw.json())
      .then(respuesta => {
         ip = respuesta.ip;        
      });
      const Token = await axios.post('https://api.trevol.com.co/login', {
        business: business,
        email: email,
        password: password,
        ip:ip
      });
      localStorage.setItem('Token',Token.data.accessToken);
      localStorage.setItem('Profile',Token.data.profile);
      localStorage.setItem('IDEMP', business);  
      localStorage.setItem('Email', email);     
      navigate.push("/dashboard");
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.msg);
      } else {
        console.error("algun error");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };  

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <img src={logo}></img> 
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Iniciar sesión</Title>             
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="empresa"
                  label="Empresa"
                  name="empresa"
                  value={business} onChange={(e) => setBusiness(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu Empresa!",
                    },
                  ]}
                >
                  <Input placeholder="Empresa" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Correo"
                  name="email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu Correo!",
                    },
                  ]}
                >
                  <Input placeholder="Correo" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Contraseña"
                  name="password"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "or favor ingresa tu contraseña!",
                    },
                  ]}
                >
                  <Input type="Password" placeholder="Contraseña" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={onChange} />
                  Recordarme
                </Form.Item>                
                
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Ingresar
                  </Button>
                </Form.Item>               
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={0 }
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img className="GraficaLogin" style={{maxWidth:"450px", position:"fixed" , marginTop:"10%" }} src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>        
      </Layout>
    </>
  );
}
export default SignIn