/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import axios from "axios";
import "./index.css"
import { msalConfig } from './authConfig';
import { PublicClientApplication, EventType } from "@azure/msal-browser";

axios.defaults.withCredentials = false;
export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance?.getAllAccounts();
if (accounts && accounts.length > 0) {
    msalInstance?.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if ((msalInstance?.getAllAccounts().length > 0) && event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload ;
        const account = payload.account;
        msalInstance?.setActiveAccount(account);
        console.log("Set usuario activo: ", account);
    }
});
ReactDOM.render(
  <BrowserRouter>    
      <App />    
  </BrowserRouter>,
  document.getElementById("root"),
);
