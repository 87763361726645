import React from 'react';

import { PowerBiWorkspace, PowerBiReport, PowerBiDataset } from './models/PowerBiModels';

export interface AppContextData {
    workspaces?: PowerBiWorkspace[];
    currentWorkspaceId?: string;
    currentWorkspaceName?: string;
    currentWorkspaceIsReadOnly?: boolean;
    currentWorkspaceIsPremium?: boolean;    
    reports?: PowerBiReport[];
    datasets?: PowerBiDataset[];
    workspaceArtifactsLoading?: boolean
}

export const AppContextDataDefaults: AppContextData = {
    currentWorkspaceId: "",
    currentWorkspaceName: "",
    currentWorkspaceIsReadOnly: false,
    currentWorkspaceIsPremium: false,
    workspaces: [],
    reports: [],
    datasets: [],
    workspaceArtifactsLoading: false
}

export interface AppContextProps {
    appContextData: AppContextData;
    setCurrentWorkspaceId: (WorkspaceId: string) => void;
    refreshReportList: () => void;
  }

  export const AppContext = React.createContext<AppContextProps>({
    appContextData: AppContextDataDefaults,
    setCurrentWorkspaceId: (WorkspaceId: string) => {},
    refreshReportList: () => {}
  });